import { Button, Col, Input, message, Row, Select, Spin, Switch, Typography } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import network from '../../helpers/network';
import { deployType } from './DeployListWrapper';
import * as AWS from 'aws-sdk';

interface propsInterface {
  item: any;
  deployList: deployType;
  setDeployList: (v: deployType) => void;
  isDeploying: boolean;
  environment: string;
}

const DeployListItem = ({ item, deployList, setDeployList, isDeploying, environment }: propsInterface) => {
  const prevDeploying = useRef(false);
  const [latestRelease, setLatestRelease] = useState<string | null>(null);
  const [isLoadingLatestRelease, setLoadingLatestRelease] = useState<boolean>(false);

  const [deployStatus, setDeployStatus] = useState<string | null>(null);
  const [isLoadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [releases, setReleases] = useState<string[]>([]);
  const [isLoadingReleasesList, setLoadingReleasesList] = useState<boolean>(false);

  const [workflowId, setWorkflowId] = useState<null | number>(null);
  const [version, setVersion] = useState('');
  const [marked, setMarked] = useState(false);
  const options = useMemo(() => {
    const optionsList = releases.map((version: string) => ({
      value: version,
      label: version,
    }));
    return optionsList;
  }, [releases]);

  const isItemLoading = isLoadingLatestRelease || isLoadingStatus || isLoadingReleasesList;

  // const getLatestRelease = async () => {
  //   setLoadingLatestRelease(true);

  //   const [ok, res] = await network('get', `${item.repo_name}/releases/latest`);

  //   if (ok && res.tag_name) {
  //     setLatestRelease(res.tag_name);
  //     setVersion(res.tag_name);
  //   }

  //   setLoadingLatestRelease(false);
  // };
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const tableName = "deploy-tools-states"

  const getLatestRelease = async () => {
    AWS.config.update({
      region: 'us-east-1',
      // endpoint: 'dynamodb.us-east-1.amazonaws.com',
      secretAccessKey: 'UhYvAxd6d+H+FkB6gQYzBhMX65I3O+K5e7dch9t0',
      accessKeyId: 'AKIAYDW4TNB3ZRMYM3OO'
    });
  
    // const fetchDataFormDynamoDb = () => {
    //   fetchData()
    // }
    const docClient = new AWS.DynamoDB.DocumentClient()
    // const [environment, setEnvironment] = useState('');
    var params = {
      TableName: tableName,
      FilterExpression : 'Environment = :environment and Component = :component',
      ExpressionAttributeValues : {':environment' : environment, ':component' : item.repo_name}
    }
    // console.log(item.repo_name);

    docClient.scan(params, function(err, data) {
      if (err) console.log(err);
      else {
        if (data.Items) {
          if (data.Items[0]) {
            // console.log(data.Items[0].Version);
            setLatestRelease(data.Items[0].Version);
            getDeployStatus(data.Items[0].ActionID);
          } else {
            setLatestRelease(null);
          }
        }
        // setLatestRelease(data.Items[0].version);
      }
    });
    // setLatestRelease(data.items);
    // setVersion(res.tag_name);

    
  }
  ////

  
  //////////////////////////////////

  const getDeployStatus = async (actionid: string) => {
    setLoadingStatus(true);

    if (actionid) {
      const [ok, res] = await network('get', `${item.repo_name}/actions/runs/${actionid}`);
      if (!ok) {
        message.error(res.message);
      }
      if (ok) {
        // for (let i = 0; i <= res.workflow_runs.length; i++) {
          const workflow = res;
          // if (!workflow?.head_branch) {
          //   continue;
          // }
  
          if (workflow.conclusion === null) {
            setDeployStatus('In progress');
          } else {
            setDeployStatus(workflow.conclusion);
          }
        // }
      }

    } else {
      setDeployStatus('Not deployed');

    }

    setLoadingStatus(false);
  };

  const getReleasesList = async () => {
    setLoadingReleasesList(true);

    const [ok, res] = await network('get', `${item.repo_name}/releases`);

    if (!ok) {
      message.error(res.message);
    }

    if (ok && res) {
      setReleases(res.map((r: any) => r.tag_name));
    }

    setLoadingReleasesList(false);
  };

  const getWorkflowId = async () => {
    const [ok, res] = await network('get', `${item.repo_name}/actions/workflows`);

    if (!ok) {
      message.error(res.message);
    }

    if (ok && res.workflows.length > 0) {
      for (let i = 0; i < res.workflows.length; i++) {
        const workflow = res.workflows[i];
        if (workflow.path === '.github/workflows/main.yaml' || workflow.path === '.github/workflows/main.yml') {
          message.info("SET WORKFLOW - " + workflow.id);
          setWorkflowId(workflow.id);
          break;
        }
      }
    }
  };

  useEffect(() => {
    // console.log(environment)
    getLatestRelease();
  }, [environment]);

  useEffect(() => {
    if (item.repo_name) {
      getLatestRelease();
      getReleasesList();
      getWorkflowId();
    }
  }, [item]);

  // useEffect(() => {
  //   if (!latestRelease) return;

  //   getDeployStatus();
  // }, [latestRelease]);

  useEffect(() => {
    if (prevDeploying.current && !isDeploying) {
      setMarked(false);
      setTimeout(() => {
        getLatestRelease();
        getReleasesList();
        getWorkflowId();
      }, 1500);
    }

    prevDeploying.current = isDeploying;
  }, [isDeploying]);

  const handleVersion = (v: string) => {
    setVersion(v);
    if (marked) {
      setDeployList({
        ...deployList,
        [item.repo_name]: {
          version: v,
          workflowId: workflowId,
        },
      });
    }
  };

  const handleMarked = (v: boolean) => {
    setMarked(v);
    if (v) {
      setDeployList({
        ...deployList,
        [item.repo_name]: {
          version: version,
          workflowId: workflowId,
        },
      });
    } else {
      const list = { ...deployList };
      delete list[item.repo_name];
      setDeployList(list);
    }
  };

  const handleUpdate = async () => {
    if (!workflowId) return;

    handleMarked(false);
    const [ok, res] = await network(
      'post',
      `${item.repo_name}/actions/workflows/${workflowId}/dispatches`,
      {
        ref: version,
        inputs: {
          tag: version,
          environment_type:environment
        },
      },
    );

    if (!ok) {
      message.error('something wrong');
    }

    if (ok) {
      message.success('Deployed successfully');
    }
  };

  return (
    <Spin spinning={isItemLoading}>
      <Row gutter={8} style={{ marginBottom: 8 }}>
        <Col span={4}>
          <Typography.Text strong>{item.repo_name}</Typography.Text>
        </Col>
        <Col span={4}>
          <Input value={latestRelease || ''} readOnly disabled />
        </Col>
        <Col span={4}>
          <Input value={deployStatus || ''} readOnly disabled />
        </Col>
        <Col span={4}>
          <Select
            options={options}
            style={{ width: '100%' }}
            defaultValue={latestRelease}
            value={version}
            onChange={handleVersion}
          />
        </Col>
        <Col span={1}>
          <Switch checked={marked} onChange={handleMarked} />
        </Col>
        <Col span={4}>
          <Button
            type={'primary'}
            disabled={version === latestRelease}
            style={{ width: '100%' }}
            onClick={handleUpdate}
          >
            Deploy
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default DeployListItem;
