import {Button, Layout, Typography} from "antd";
import Container from "../Container/Container";
import "./PageHeader.scss"
// import {AuthEventData} from "@aws-amplify/ui/dist/types/types/authenticator/stateMachine/event.d.ts"

const {Header} = Layout

type Props = {
    userName: string
    handleSignOut:  ((data?: (Record<PropertyKey, any> | undefined)) => void) | undefined
}

const PageHeader = ({userName, handleSignOut}:Props) => {
    return (
        <Header className={'pageHeader'}>
            <Container className={'pageHeaderContainer'}>
                <>
                    <Typography.Text color={'white'} className={'userName'}>Welcome {userName}</Typography.Text>
                    <Button size={'small'} type={'primary'} onClick={handleSignOut}>Sign Out</Button>
                </>
            </Container>
        </Header>
    )
}

export default PageHeader