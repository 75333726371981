import './Container.scss';

interface ComponentInterface {
  children: JSX.Element;
  className?: string;
}

const Container = ({ children, className }: ComponentInterface) => {
  return <div className={`Container ${className}`}>{children}</div>;
};

export default Container;
