import axios from 'axios';

const network = async (method: string, path: string, body?: any) => {
  let config = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: path, // Set the url based on the 'type' header value
    method,
    data: body ?? undefined,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_GIT_TOKEN}`,
    },
  };

  try {
    let res = await axios(config);
    return [true, res.data as any];
  } catch (err: any) {
    return [false, err as any];
  }
};

export default network;
