import { Button, Col, Divider, Row, Select, Spin } from 'antd';
import DeployListItem from './DeployListItem';
import { Typography } from 'antd';
import { useState } from 'react';
import network from '../../helpers/network';

const { Text } = Typography;

const environmentOptions = [
  {
    value: 'production',
    label: 'Production',
  },
  {
    value: 'preprod',
    label: 'PreProd',
  },
  {
    value: 'sandbox',
    label: 'Sandbox',
  },
  {
    value: 'experimental',
    label: 'Experimental',
  },
  {
    value: 'development-experimental-sandbox',
    label: 'Development-Experimental',
  },
  {
    value: 'development',
    label: 'Development',
  },
];

interface propsInterface {
  items: any[];
}

export type deployType = {
  [key: string]: {
    version: string;
    workflowId: number;
  };
};

const DeployListWrapper = ({ items }: propsInterface) => {
  const [environment, setEnvironment] = useState('sandbox');
  const [deployList, setDeployList] = useState<deployType>({});
  const [isLoadingAll, setLoadingAll] = useState<boolean[]>([]);
  const isDeploying = isLoadingAll.includes(true);

  const startDeploy = async (name: string, version: string, workflowId: number, index: number) => {
    let loadArr = [...isLoadingAll];
    loadArr[index] = true;
    setLoadingAll(loadArr);
    await network('post', `${name}/actions/workflows/${workflowId}/dispatches`, {
      ref: version,
      inputs: {
        tag: version,
        environment_type: environment,
      },
    });

    loadArr = [...isLoadingAll];
    loadArr[index] = false;
    setLoadingAll(loadArr);
  };

  const handleDeployAll = async () => {
    const deployArr = Object.keys(deployList);
    deployArr.forEach((name, index) => {
      const version = deployList[name].version;
      const workflowId = deployList[name].workflowId;
      startDeploy(name, version, workflowId, index);
    });

    setDeployList({});
  };

  return (
    <div>
      <Spin spinning={isDeploying}>
        <Row style={{ marginBottom: 24 }} gutter={8}>
          <Col span={5} offset={16} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <Text strong>Environment</Text>
            <Select
              placeholder={'Select'}
              value={environment}
              options={environmentOptions}
              onChange={setEnvironment}
              style={{ flex: 1 }}
              defaultValue={environment}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }} gutter={8}>
          <Col span={4}>
            <Text>Component Name</Text>
          </Col>
          <Col span={4}>
            <Text>Current version</Text>
          </Col>
          <Col span={4}>
            <Text>Current status</Text>
          </Col>
          <Col span={4}>
            <Text>Versions</Text>
          </Col>
          <Col span={4}>
            <Text>To deploy</Text>
          </Col>
        </Row>
        {items.map((item: any) => {
          return (
            <DeployListItem
              key={item.repo_name}
              item={item}
              setDeployList={setDeployList}
              deployList={deployList}
              isDeploying={isDeploying}
              environment={environment}
            />
          );
        })}
        <Divider />
        <Row gutter={8}>
          <Col span={4} offset={17}>
            <Button
              type={'primary'}
              style={{ width: '100%' }}
              disabled={Object.values(deployList).length === 0}
              onClick={handleDeployAll}
            >
              Deploy all marked repos
            </Button>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default DeployListWrapper;
