import { Card, Tabs, TabsProps } from 'antd';
import DeployListWrapper from '../DeployListWrapper/DeployListWrapper';

const mockData = [
  {
    project: 'Scoping Tool',
    items: [
      {
        repo_name: 'scoping-tool-backend',
        repo_url: 'https://github.com/eScribers/scoping-tool-backend',
      },
      {
        repo_name: 'scoping-tool',
        repo_url: 'https://github.com/eScribers/scoping-tool',
      },
      {
        repo_name: 'WebScribeDocumentManager',
        repo_url: 'https://github.com/eScribers/WebScribeDocumentManager',
      },
    ],
  },
  {
    project: 'Tabula',
    items: [
      {
        repo_name: 'tabula',
        repo_url: 'https://github.com/eScribers/tabula',
      },
      {
        repo_name: 'tabula-react',
        repo_url: 'https://github.com/eScribers/tabula-react',
      },
      {
        repo_name: 'conf-app',
        repo_url: 'https://github.com/eScribers/conf-app',
      },
    ],
  },
];

const DeployTabs = () => {
  const items: TabsProps['items'] = mockData.map((item, index) => {
    return {
      key: String(index),
      label: item.project,
      children: <DeployListWrapper items={item.items} />,
    };
  });

  return (
    <Card>
      <Tabs items={items} />
    </Card>
  );
};

export default DeployTabs;
