import React from 'react';
import {Layout} from 'antd';
import Container from './components/Container/Container';
import DeployTabs from './components/DeployTabs/DeployTabs';
import {Amplify} from 'aws-amplify';
import awsExports from './aws-exports';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import PageHeader from "./components/PageHeader/PageHeader";

Amplify.configure({
    Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
    }
})

function App() {
    return (
        <Layout>
            <Authenticator>
                {({signOut, user}) => (
                    <>
                        <PageHeader userName={user?.username || ''} handleSignOut={signOut}/>
                        <Container>
                            <DeployTabs/>
                        </Container>
                    </>
                )}

            </Authenticator>
        </Layout>
    );
}

export default App;